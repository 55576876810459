import grumpy_kitty from './grumpy-kitty.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={grumpy_kitty} className="App-logo" alt="hero cat" />
        <p>
          Definitely not.
        </p>
      </header>
    </div>
  );
}

export default App;
